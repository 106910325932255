<template>
    <v-dialog v-model="dialog.show"  hide-overlay transition="dialog-bottom-transition" class="emp">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Cancel Task</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text :disabled="!valid" @click="save()">Submit</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader>Cancelling Task</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Fields</v-list-item-title>
              <v-list-item-subtitle>Please make sure to fill all required fields</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list three-line subheader>
          <v-subheader>Cancel Task Form</v-subheader>
          <v-list-item>
            <v-text-field
              v-model="task.why"
              :error-messages="!validate(task.why, true )? ['[Why] is required ']: []"
              type="text"
              label="Why?"
              color="primary"
            />
          </v-list-item>
          <v-list-item>
            <v-text-field
              v-model="task.notes"
              :error-messages="!validate(task.notes, false, 1)? ['[Notes] is not valids']: []"
              type="text"
              label="Notes"
              color="primary"
            />
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
</template>
<script>
export default {
  methods: {
    save() {
      if (this.valid) {
        this.dialog.show = false;
        this.$emit("submit_cancel", JSON.parse(JSON.stringify(this.task)));
      }
    },
    clear() {
      this.task.why = "";
      this.task.notes = "";
    },
    validate(value,required=false, length=3){
      return (typeof value == "string" && value?.length >= length) || (!required && value?.length == 0);
    }
  },
  watch: {
    show(val) {
      if (val == true) {
        this.clear();
      }
    }
  },
  data() {
    return {
      task: {
        why: "",
        notes: ""
      }
    };
  },
  props: {
    dialog: {
      type: Object,
      default: ()=>({show:false})
    }
  },
  computed: {
    show(){
      return this.dialog.show
    },
    valid() {
      let condition = true;
      condition &=this.validate(this.task.why,true);
      condition &=this.validate(this.task.notes,false);
      return condition;
    }
  }
};
</script>

<style>
.emp{
     border-radius: 0;
    margin: 0;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
}
</style>